import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const videos = [
  {
    url: 'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/Japan+Video/JAPANFINAL.mp4',
    title: 'Japan (2023)',
    poster: 'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/Thumbnails/Japanthumb.png', 
  },
  {
    url: 'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/DRFinal.mov',
    title: 'Dominican Republic (2024)',
    poster: 'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/Thumbnails/DRthumb.png',
  },
  {
    url: 'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/soydelranchofinal.mp4',
    title: 'Soy Del Rancho (2024)',
    poster: 'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/Thumbnails/sdrthumb.png',
  }
];

const projectImages = {
  MacksMacksa: [
    'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/COVERmacks4.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/macksbelt.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/mackscrouching.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/macksdodgerhat.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/mackssideprofile.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/mackssmiling.jpg',
  ],
  
  KIO: [
    'https://jaslopezportfolio.s3.amazonaws.com/KIO/COVER+KIO.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/KIO/deviltat.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/KIO/earthtat.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/KIO/feelreal.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/KIO/headtat.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/KIO/slowdownspeed.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/KIO/watchmyback.jpg',

  ],

  LifeisABeach: [
    'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/CoverJazz.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzblur.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonrock1.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonrock4.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonsand2.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonsand3.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonsand5.jpeg',
  ],

  SoyDelRancho: [
    'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/Cover_sdr.jpg',
    'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr1.jpg',
    'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr2.jpg',
    'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr3.jpg',
    'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr4.jpg',
    'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr5.jpg',
    'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr6.jpg',
  ],

  DominicanRepublic: [
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/COVERbeachDR2.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/barDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/basketballDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/beachDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/boysandDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/boyseaDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/buildingDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/cowsDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/flagDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/hurtDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/ladiesDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/menonbikeDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/quinceDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/motosDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/quoteDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/redtruckDR.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/streetDR.jpg',
  ],

  Japan: [
    'https://jasportfolio.s3.amazonaws.com/Japan/smokinginjapan.jpg',
    'https://jasportfolio.s3.amazonaws.com/Japan/trainstationJapan.jpg',
    'https://jasportfolio.s3.amazonaws.com/Japan/whitebuildinginjapan.jpg',
    'https://jasportfolio.s3.amazonaws.com/Japan/womanonbikeinjapan.jpg',
  ],

  SembrandoFlores: [
    'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/cover3.jpg',
    'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/sembrando1.jpg',
    'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/sembrando2.jpg',
    'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/sembrando3.jpg',
    'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/sembrando4.jpg',
    'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/cover4.jpg',
  ],
  Dad: [
    'https://jasportfolio.s3.amazonaws.com/Dad/dadandnephewonanexpiredrolloffilm.jpg',
    'https://jasportfolio.s3.amazonaws.com/Dad/parentsonanexpiredrolloffilm.jpg',
    'https://jasportfolio.s3.amazonaws.com/Dad/dadonanexpiredrolloffilm.jpg',
  ],
  MothersDaySeries: [
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/COVERjmom1.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/amom1.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/amom2.jpg',
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/cristina1.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/cristina2.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/francisca1.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/francisca2.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/jmom2.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/karina1.jpeg',
    'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/karina2.jpeg',
  ],

};

const CustomArrow = ({ direction, onClick }) => (
  <button className={`slick-arrow slick-${direction}`} onClick={onClick}>
    {direction === 'prev' ? (
      <i className="fas fa-arrow-left text-white text-4xl"></i>
    ) : (
      <div id="project-next">
        <div id="next-tooltip">Next</div>
      </div>
    )}
  </button>
);

const Archive = () => {
  const [showSlider, setShowSlider] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sliderRef.current && !sliderRef.current.contains(event.target)) {
        closeSlider();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openSlider = (folder) => {
    const images = projectImages[folder];
    if (images) {
      setSliderImages(images);
      setCurrentImageIndex(0);
      setShowSlider(true);
    } else {
      console.error('Images not found for folder:', folder);
    }
  };

  const closeSlider = () => {
    setShowSlider(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    lazyLoad: 'ondemand',
    adaptiveHeight: true,
  };

  return (
    <div className="gallery-container m-10 mt-10">
      <div className="grid md:grid-cols-3 gap-6">
        {Object.keys(projectImages).map((folder, index) => (
          <div
            key={index}
            className="aspect-w-1 aspect-h-1 relative cursor-pointer overflow-hidden shadow-lg"
            onClick={() => openSlider(folder)}
          >
            <img
              src={projectImages[folder][0]}
              alt={`Project ${folder}`}
              className="object-cover w-full h-full transition-transform duration-300 ease-in-out transform hover:scale-110"
              onError={(e) => {
                e.target.src = projectImages[folder][1];
              }}
            />
          </div>
        ))}
      </div>

      {showSlider && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex justify-center items-center">
          <div className="relative max-w-3xl w-full p-4" ref={sliderRef}>
            <button
              className="absolute top-10 right-10 m-4 text-white z-10"
              onClick={closeSlider}
            >
              Close
            </button>
            <Slider {...settings} initialSlide={currentImageIndex}>
              {sliderImages.map((image, index) => (
                <div key={index} className="h-screen flex flex-col justify-center items-center">
                  <img
                    src={image}
                    alt={`Project ${index + 1}`}
                    className="object-contain h-full max-w-full"
                  />
                  <p className="text-white text-lg mt-2">{`Image ${index + 1}`}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}

      <div className="container mx-auto sm:px-6 md:px-0 mt-10">
        <div className="grid md:grid-cols-2 gap-10 mt-5">
          {videos.map((video, index) => (
            <div key={index} className="overflow-hidden shadow-lg">
              <video
                src={video.url}
                title={`Video ${index + 1}`}
                className="w-full h-64 object-cover"
                controls
                poster={video.poster}
                referrerPolicy="strict-origin-when-cross-origin"
              ></video>

              <div className="p-4 bg-white">
                <p>
                  <span className="font-bold italic">{video.title}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Archive;