import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImgGallery = () => {
  const [showSlider, setShowSlider] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const sliderRef = useRef(null);

  const projectImages = {
    MorganLynzi: [
      'https://jaslopezportfolio.s3.amazonaws.com/Morgan+Lynzi/COVERmorgan.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Morgan+Lynzi/3genmorgan.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Morgan+Lynzi/grandmamorgan.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Morgan+Lynzi/archwaymorgan.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Morgan+Lynzi/treemorgan.jpeg',
    ],

    MacksMacksa: [
      'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/COVERmacks4.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/macksbelt.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/mackscrouching.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/macksdodgerhat.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/mackssideprofile.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/MacksMacksa/mackssmiling.jpg',
    ],
    KIO: [
      'https://jaslopezportfolio.s3.amazonaws.com/KIO/COVER+KIO.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/KIO/deviltat.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/KIO/earthtat.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/KIO/feelreal.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/KIO/headtat.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/KIO/slowdownspeed.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/KIO/watchmyback.jpg',

    ],
    
    LifeisABeach: [
      'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/CoverJazz.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzblur.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonrock1.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonrock4.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonsand2.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonsand3.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/Life+is+a+Beach/Jazzonsand5.jpeg',
    ],
  

    SoyDelRancho: [
      'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/Cover_sdr.jpg',
      'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr1.jpg',
      'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr2.jpg',
      'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr3.jpg',
      'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr4.jpg',
      'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr5.jpg',
      'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/SoyDelRancho/sdr6.jpg',
    ],
  
    DominicanRepublic: [
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/COVERbeachDR2.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/barDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/basketballDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/beachDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/boysandDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/boyseaDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/buildingDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/cowsDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/flagDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/hurtDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/ladiesDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/menonbikeDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/quinceDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/motosDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/quoteDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/redtruckDR.jpg',
      'https://jaslopezportfolio.s3.amazonaws.com/DominicanRepublic/streetDR.jpg',

  ],

    AijahsBirthday: [
      'https://jaslopezportfolio.s3.amazonaws.com/AijahsBirthday/COVERportrait5.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/AijahsBirthday/portrait10.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/AijahsBirthday/portrait11.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/AijahsBirthday/portrait12.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/AijahsBirthday/portrait2.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/AijahsBirthday/portrait3.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/AijahsBirthday/portrait7.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/AijahsBirthday/portrait8.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/AijahsBirthday/portrait9.jpeg',
    ],

    MothersDaySeries: [
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/COVERjmom1.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/amom1.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/amom2.jpg	',
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/cristina1.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/cristina2.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/francisca1.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/francisca2.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/jmom2.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/karina1.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MothersDaySeries/karina2.jpeg',

    ],

    MeliyahsGraduation: [
      'https://jaslopezportfolio.s3.amazonaws.com/MeliyahsGraduation/COVERucla2.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MeliyahsGraduation/ucla1.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MeliyahsGraduation/ucla3.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MeliyahsGraduation/ucla4.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/MeliyahsGraduation/ucla5.jpeg',
      
    ],

    NancysGraduation: [
      'https://jaslopezportfolio.s3.amazonaws.com/NancysGraduation/COVERirvine4.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/NancysGraduation/irvine1.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/NancysGraduation/irvine2.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/NancysGraduation/irvine3.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/NancysGraduation/irvine5.jpeg',
    ],

    RysBday: [
      'https://jaslopezportfolio.s3.amazonaws.com/RysBday/COVERryan2.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/RysBday/ryan1.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/RysBday/ryan3.jpeg',
      'https://jaslopezportfolio.s3.amazonaws.com/RysBday/ryan4.jpeg',

    ],

    STFUPodcast : [
      'https://jaslopezportfolio.s3.us-west-1.amazonaws.com/noborderSTFU/IMG4061-R01-018.jpg',
      'https://jasportfolio.s3.amazonaws.com/STFUPodcast/redlightstfu.png',
      'https://jasportfolio.s3.amazonaws.com/STFUPodcast/collagestfu.jpg',
    ],

    KembeX: [
      'https://jasportfolio.s3.amazonaws.com/KembeX/shadowgirl2.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/cover.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/artdepartment1.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/chessscene1.jpeg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/jazzdiggingtomb.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/kembeandproduction.jpeg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/kembegrill.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/kembeholdingnewspaper.jpeg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/kembeincar.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/kembeintomb.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/nailsondoorframe.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/sadethedp.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/shadowgirl1.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/shadowgirlincar.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/shadowgirlspotlight.jpg	',
      'https://jasportfolio.s3.amazonaws.com/KembeX/shadowgirlunderbedsheets.jpg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/tombscene2.jpeg',
      'https://jasportfolio.s3.amazonaws.com/KembeX/tvstatic.jpg',
    ],

    SembrandoFlores : [
      'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/cover3.jpg',
      'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/cover4.jpg',
      'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/sembrando1.jpg',
      'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/sembrando2.jpg',
      'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/sembrando3.jpg',
      'https://jasportfolio.s3.amazonaws.com/SembrandoFlores/sembrando4.jpg',
    ],

    
    Dad: [
     'https://jasportfolio.s3.amazonaws.com/Dad/dadandnephewonanexpiredrolloffilm.jpg',
      'https://jasportfolio.s3.amazonaws.com/Dad/parentsonanexpiredrolloffilm.jpg	',
      'https://jasportfolio.s3.amazonaws.com/Dad/dadonanexpiredrolloffilm.jpg	',
    ],

    Japan: [
      'https://jasportfolio.s3.amazonaws.com/Japan/smokinginjapan.jpg',
      'https://jasportfolio.s3.amazonaws.com/Japan/trainstationJapan.jpg',
      'https://jasportfolio.s3.amazonaws.com/Japan/whitebuildinginjapan.jpg',
      'https://jasportfolio.s3.amazonaws.com/Japan/womanonbikeinjapan.jpg',

    ],


    Elley: [
      'https://jasportfolio.s3.amazonaws.com/Elley/cover9.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley1.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley10.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley11.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley12.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley2.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley3.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley4.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley5.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley6.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley7.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley8.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Elley/elley9.jpeg',

    ],

    BeckyG: [
      
      'https://jasportfolio.s3.amazonaws.com/beckyg/Scan 1.jpg',
      'https://jasportfolio.s3.amazonaws.com/beckyg/Scan 2.jpg',
      'https://jasportfolio.s3.amazonaws.com/beckyg/Scan 3.jpg',
      'https://jasportfolio.s3.amazonaws.com/beckyg/Scan 4.JPEG',
      'https://jasportfolio.s3.amazonaws.com/beckyg/Scan 5.JPEG',
      'https://jasportfolio.s3.amazonaws.com/beckyg/Scan 6.JPEG',
      'https://jasportfolio.s3.amazonaws.com/beckyg/Scan 7.JPEG',
      'https://jasportfolio.s3.amazonaws.com/beckyg/Scan 8.JPEG',
      'https://jasportfolio.s3.amazonaws.com/beckyg/Scan 8.jpg',

    
    ],

    Teezo: [
      'https://jasportfolio.s3.amazonaws.com/Teezo/cover18.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/cover19.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/accessories18.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/arguingscene.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/artdep.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/greenscreen.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/cover20.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/partyextra1.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/partyextra2.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/partyextra3.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/partyextra5.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/partyextra6.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/partyextra7.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/production1.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/rainscene.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/slate.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/cover20.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/stylistsign.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/TeezoandDP.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/teezoandref.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/teezobed.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/teezobench.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/tylerholdingslate.jpeg',
      'https://jasportfolio.s3.amazonaws.com/Teezo/stylist1.jpeg',
    ],


    ByEmmberNails: [
      'https://jasportfolio.s3.amazonaws.com/ByEmmersNails/applyinglipliner.jpeg',
      'https://jasportfolio.s3.amazonaws.com/ByEmmersNails/bothhandscoveringface.jpeg',
      'https://jasportfolio.s3.amazonaws.com/ByEmmersNails/coloringbook.jpeg',	
      'https://jasportfolio.s3.amazonaws.com/ByEmmersNails/giselleupsidedown.jpeg',	
      'https://jasportfolio.s3.amazonaws.com/ByEmmersNails/giselleupsidedownflash.jpeg',	
      'https://jasportfolio.s3.amazonaws.com/ByEmmersNails/handonchin.jpeg',	
      'https://jasportfolio.s3.amazonaws.com/ByEmmersNails/nailshop.jpeg',	
      'https://jasportfolio.s3.amazonaws.com/ByEmmersNails/onehandfacecover.jpeg',	
      'https://jasportfolio.s3.amazonaws.com/ByEmmersNails/sanrionails.jpeg',
    ],

    HereNow: [
      'https://jasportfolio.s3.amazonaws.com/HereNow/isaakholdingpropgun.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/cover.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/amordevilhorns.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/angelholdinggun.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/angelreflection.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/coupleholdingeachother.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/directoranddplaughing.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/dpanddirectorunderwaterscene.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/dpshootingcar.jpeg	',
      'https://jasportfolio.s3.amazonaws.com/HereNow/girlsinwater.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/haircut.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/horse.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/jazzanddirector.jpeg',
      'https://jasportfolio.s3.amazonaws.com/HereNow/dpanddirectorunderwaterscene.jpeg',

    ],

    NYFW: [
      'https://jasportfolio.s3.amazonaws.com/Personal+Archive/mazurbatedjingNYFW.JPG',
      'https://jasportfolio.s3.amazonaws.com/Personal+Archive/CharlixcxNYFW.JPG',
      'https://jasportfolio.s3.amazonaws.com/Personal+Archive/tokischaNYFW.JPG',
    ],


  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sliderRef.current && !sliderRef.current.contains(event.target)) {
        closeSlider();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openSlider = (folder) => {
    console.log("openSlider called for folder:", folder);
    const images = projectImages[folder];
    if (images) {
      setSliderImages(images);
      setCurrentImageIndex(0);
      setShowSlider(true);
      console.log("Slider should be open now.");
    } else {
      console.error('Images not found for folder:', folder);
    }
  };

  const closeSlider = () => {
    setShowSlider(false);
  };

  const handleSlideChange = (oldIndex, newIndex) => {
    setCurrentImageIndex(newIndex);
  };

  const CustomArrow = ({ direction, onClick }) => (
    <button
      className={`slick-arrow slick-${direction} text-white text-4xl z-50 focus:outline-none`}
      onClick={onClick}
      style={{
        display: 'block',
        background: 'transparent',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        [direction === 'prev' ? 'left' : 'right']: '10px',
      }}
    >
      {direction === 'prev' ? '<' : '>'}
    </button>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    lazyLoad: 'ondemand',
    adaptiveHeight: true,
    beforeChange: handleSlideChange,
  };

  return (
    <div className="gallery-container m-10 mt-10">
      <div className="grid md:grid-cols-4 gap-10 mt-10">
        {Object.keys(projectImages).map((folder, index) => (
          <div key={index} className="aspect-w-1 aspect-h-1">
            <img
              className="cursor-pointer object-cover object-center w-full h-full transform transition-transform duration-300 hover:scale-105"
              src={`${projectImages[folder][0]}`}
              onError={(e) => { e.target.src = `${projectImages[folder][1]}`; }}
              alt={`Project ${folder}`}
              onClick={() => openSlider(folder)}
            />
          </div>
        ))}
      </div>
      {showSlider && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex justify-center items-center">
          <div className="max-w-3xl w-full relative" ref={sliderRef}>
          <button
              onClick={closeSlider}
              className="absolute top-2 right-2 text-white text-2xl z-50 p-2 bg-gray-800 bg-opacity-75 rounded-full focus:outline-none"
            >
              &times; {/* Close button (Fix for mobile visibility) */}
            </button>
            <Slider {...settings} initialSlide={currentImageIndex}>
              {sliderImages.map((image, index) => (
                <div key={index} className="h-screen flex flex-col justify-center items-center text-center">
                 <img src={image} alt={`Project ${index + 1}`} className="object-contain h-full max-w-full" style={{ margin: '0 auto' }} />
                </div>
              ))}
            </Slider>
            <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2 text-white text-lg md:text-xl">
            {`${currentImageIndex + 1}/${sliderImages.length}`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImgGallery; 